body {
  font-family: "Open Sans";
  background: #fdfdfd;
}

.container {
  max-width: 1100px;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  width: 100%;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

input {
  outline: 0;
}

select {
  outline: 0;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 10px;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.gap-10 {
  gap: 10px;
}

p {
  color: #28282b;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
}

.primary-font {
  font-family: "Mandatory Plaything";
}

.common-header {
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: #fff;
  box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.1);
  padding: 15px 30px;
  position: -webkit-sticky;
  position: fixed;
  top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  left: 0;
  right: 0;
  max-width: 1100px;
  margin: auto;
}

.main-logo {
  line-height: 0;
}

.main-logo svg {
  height: 42px;
  width: 100%;
}

.search-nav-bar input {
  border-radius: 50px;
  background: #f1f1f1;
  border: 0;
  padding: 15px;
  width: 410px;
  padding-left: 50px;
}

.search-nav-bar input[type="search"] {
  background-image: url("../Images/search-icon.svg");
  background-position: 22px 18px;
  background-repeat: no-repeat;
  color: #28282b;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.search-nav-bar input:focus-visible {
  border: 0;
  outline: 0;
}

.banner-main-text>div h1 {
  margin-top: 10px;
  margin-bottom: 0px;
}

.banner-main-text>div {
  margin-bottom: 20px;
}

.banner-main-text h1 {
  color: #28282b;
  text-align: center;
  font-size: 35px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 6.75px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* .banner-main-text p {
  color: #28282b;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  margin: 0;
} */

.banner-main-text h2 {
  color: #28282b;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  margin: 0;
}

.banner-main-text h1 span {
  margin: 0px 10px;
}

.banner-part {
  /* height: 30vh; */
  padding-top: 200px;
  display: flex;
  align-items: end;
  justify-content: center;
  margin-bottom: 50px;
}

.banner-calcuvate img {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.1);
}

.w-80 {
  width: 80%;
  margin: auto;
}

.banner-calcuvate p {
  color: #28282b;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
}

.calcuvate-category {
  padding: 60px 0px;
  padding-top: 30px;
}

.category-single-box img {
  width: 100%;
  height: 110px;
}

.calcuvate-category-box {
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  padding: 20px;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(95deg, #ffffff, #ffffff, #0bda51) border-box;
  border: 2px solid #0000;
}

.category-single-box img {
  width: 100%;
  height: auto;
}

.category-single-box {
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  text-align: center;
}

.category-single-box h2 {
  color: #28282b;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.75px;
  margin: 10px 0px;
}

.category-single-box a {
  color: #28282b;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
  margin: 8px 0px;
  text-decoration: none;
}

.category-single-box p {
  color: #28282b;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
  margin: 8px 0px;
  text-decoration: none;
}

.blogs-box-contin .blogs-box {
  width: 33.33%;
  padding: 30px;
}

.blog-main-text h2 {
  color: #28282b !important;
  font-size: 60px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 12px !important;
  margin: 0 !important;
}

/* .blogs-box-contin {
  border-radius: 20px;
  border: 2px solid #0bda51;
  background: linear-gradient(
    91deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 109.8%
  );
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(25px);
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(95deg, #ffffff, #ffffff, #3bad45) border-box;
  border: 2px solid #0000;
} */

.blogs-box-contin {
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(95deg, #fff, #fff, #0bda51) border-box;
  border: 2px solid #0000;
  border-radius: 20px;
  box-shadow: 0 10px 50px 0 #0000001a;
}

.blog-main-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blogs-box-contin .border-bottom {
  border-bottom: 1px solid #ccf6da;
}

.blogs-box-contin .border-right {
  border-right: 1px solid #ccf6da;
}

.blogs-box-contin .border-left {
  border-left: 1px solid #ccf6da;
}

.blogs-box .blog-date {
  color: #28282b;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}

.blogs-box .blog-date span {
  color: rgba(40, 40, 43, 0.5);

  font-size: 10px;
  font-weight: 600;
  line-height: normal;
}

.blogs-box h2 {
  color: #28282b;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin-top: 5px;
  /* margin-bottom: 0px; */
}

.blogs-box p {
  color: #28282b;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

.blogs-box a {
  color: #0bda51;
  font-family: "Open Sans";
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

.blogs-box-contin-row {
  display: flex;
}

.footer-sec {
  border-radius: 20px 20px 0px 0px;
  border-top: 3px solid #0bda51;
  background: rgba(11, 218, 81, 0.03);
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(25px);
  margin-top: 100px;
}

.footer-contin {
  text-align: center;
  padding: 40px 0px;
}

.footer-contin p {
  color: #28282b;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.9px;
  /* width: 43%; */
  margin: auto;
  padding: 30px 0px;
}

.footer-contin ul {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 50px;
  padding: 0;
}

.footer-contin ul li a {
  color: #28282b;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.7px;
}

.footer-all-rights p {
  color: #28282b;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.9px;
  margin: 0;
}

.our-blogs {
  padding-bottom: 100px;
}

.footer-all-rights {
  background: #fff;
  padding: 15px 0px;
}

.position-relative {
  position: relative;
}

.footer-icon img {
  position: absolute;
  top: -50px;
  z-index: 9;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(12.5px);
}

.content-cover-box {
  border-radius: 20px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(25px);
  margin-top: 150px;
  padding: 30px 20px;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(95deg, #ffffff, #ffffff, #0bda51) border-box;
  border: 2px solid #0000;
}

.calculate-card {
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%);
  box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.5px);
  padding: 25px;
  margin-bottom: 30px;
}

.calculate-percentage-label {
  color: rgba(40, 40, 43, 0.5);
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
  margin-bottom: 20px;
}

.calculate-btns {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.calculate-btns .btn-theme {
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: #0bd24e;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.5px);
  color: #fff;
  width: 240px;
  height: 45px;
  text-align: center;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
}

.calculate-btns .btn-theme-outline {
  border-radius: 12px;
  border: 1px solid #0bd24e;
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.5px);
  color: #0bd24e;
  text-align: center;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  width: 150px;
  height: 45px;
}

.calculate-row {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;
  align-items: center;
}

.calculate-row input {
  border-radius: 8px;
  background: #f1f1f1;
  border: 0;
  height: 30px;
  padding: 12px;
}

.calculate-row>div p {
  color: #28282b;
  text-align: center;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
}

.calculate-row .ad-percentage {
  background-image: url("../Images/percentage.svg");
  background-position: 155px 20px;
  background-repeat: no-repeat;
  color: #28282b;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  /* padding-right: 40px; */
}

.calculate-percentage h2 {
  color: #28282b;
  font-size: 25px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.5px;
  margin-top: 60px;
}

.calculate-percentage h1 {
  color: #28282b;
  font-size: 25px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.5px;
  margin-top: 60px;
}

.calculate-row select {
  border-radius: 8px;
  background: #f1f1f1;
  border: 0;
  padding: 12px;
  width: 185px;
  height: 55px;
  color: #28282b;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
}

.calculate-percentage p {
  color: #28282b;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
}

.result-box-contin .result-box {
  border-radius: 8px;
  background: #f1f1f1;
  padding: 10px;
  text-align: center;
  color: #28282b;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  width: 70%;
  margin: auto;
}

.result-box-contin {
  text-align: center;
}

.result-share {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.result-share {
  position: relative;
}

.result-share svg {
  position: absolute;
  right: 0;
}

.calculator-img img {
  width: 70%;
}

.calculator-img {
  display: flex;
  justify-content: center;
}

.bmi-range {
  padding: 0;
}

.bmi-range li {
  color: #28282b;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
  margin-bottom: 6px;
}

.share-link-calculator {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin: 50px 0px;
}

.share-link-calculator input {
  border-radius: 8px;
  background: #f1f1f1;
  border: 0;
  height: 30px;
  padding: 12px;
  width: 679px;
}

.share-link-btn {
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: #0bd24e;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(12.5px);
  backdrop-filter: blur(12.5px);
  color: #fff;
  width: 200px;
  height: 50px;
  text-align: center;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
}

.share-link-calculator p {
  color: rgba(40, 40, 43, 0.5);
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
  margin: 0;
}

.bmi-calculate {
  display: flex;
}

.bmi-calculate .mbi-calculate-left {
  width: 50%;
}

.mbi-calculate-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.mbi-calculate-row .mbi-label {
  width: 90px;
}

.mbi-calculate-row .mbi-label p {
  color: #28282b;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  margin: 0;
}

.mbi-calculate-row input {
  border-radius: 8px;
  background: #f1f1f1;
  border: 0;
  height: 30px;
  padding: 12px;
}

.gender-select {
  display: flex;
  align-items: center;
  gap: 10px;
}

.gender-select input {
  width: 20px;
  color: #0bda51;
  height: 20px;
}

.gender-select input[type="radio"] {
  accent-color: #40a663;
}

.gender-select span {
  color: #28282b;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
}

.mbi-calculate-row select {
  background: #f1f1f1;
  border: 0;
  border-radius: 8px;
  font-family: Open Sans;
  height: 55px;
  padding: 12px;
  width: 95px;
  color: #28282b;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
}

.mbi-calculate-left .calculate-btns {
  justify-content: left;
}

input[type="text"] {
  color: #28282b;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
}

.calculate-percentage h3 {
  color: #28282b;
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
}

.pl-80 {
  padding-left: 80px;
}

.border-left {
  border-left: 1px solid #e4e4e4;
}

.calorie-result-unit {
  padding-left: 30px;
}

.calorie-result-unit h3 {
  color: #28282b;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  margin-top: 0;
}

.result-unit-check {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.calories-count-box-left {
  border-radius: 8px;
  background: #f1f1f1;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calories-count-box-right {
  border-radius: 8px;
  background: #f1f1f1;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calories-count-list {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.calories-count-contin {
  margin-top: 20px;
}

.calories-count-box-right h3 {
  color: #28282b;
  text-align: center;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  margin: 0;
}

.calories-point-list {
  display: flex;
  justify-content: center;
  text-align: justify;
}

.calculate-fat input {
  border-radius: 8px;
  background: #f1f1f1;
  border: 0;
  height: 30px;
  padding: 12px;
  width: 30px;
}

.calculate-fat .ad-percentage {
  background-image: url("../Images/percentage.svg");
  background-position: 55px 20px;
  background-repeat: no-repeat;
  color: #28282b;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding-right: 40px;
}

.mbi-calculate-right {
  width: 50%;
}

.calculate-fat {
  display: flex;
  gap: 10px;
}

.calculate-fat-contin {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.activity-select select {
  width: 330px;
}

.result-box-contin h2 {
  color: #28282b;
  font-family: "Open Sans";
  font-size: 25px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
  margin-top: 30px;
}

.bmi-count-list {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.bmi-count-box-left {
  border-radius: 8px;
  background: #f1f1f1;
  width: 470px;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
}

.bmi-count-box-right {
  border-radius: 8px;
  background: #f1f1f1;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bmi-label-contin {
  margin-top: 30px;
}

.bmi-label-list {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.bmi-label-box-left {
  border-radius: 8px;
  width: 470px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

.bmi-label-box-right {
  border-radius: 8px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 15px;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bmi-label-box-left p {
  color: #28282b;
  text-align: center;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
}

.bmi-label-box-right p {
  color: #28282b;
  text-align: center;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.main-calcu .calculator {
  width: 580px;
  margin: 50px auto;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.main-calcu .display {
  font-size: 2em;
  height: 60px;
  background: #f1f1f1;
  margin-bottom: 20px;
  padding: 10px;
  text-align: right;
  border-radius: 5px;
}

.main-calcu .buttons {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0px;
}

.main-calcu button {
  font-size: 1em;
  padding: 20px;
  color: #7b7b7b;
  border: 1px rgba(255, 255, 255, 0.3);
  border-radius: 0px;
  cursor: pointer;
  background: #e0e0e0;
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%);
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 6%);
  -webkit-backdrop-filter: blur(12.5px);
  backdrop-filter: blur(12.5px);
  text-align: center;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
}

.small-calculator-toggle>div {
  display: flex;
  gap: 10px;
}

.small-calculator-toggle>div .false {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: #f1f1f1;
  color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px !important;
  font-size: 14px !important;
}

.small-calculator-toggle>div .calculator-button-bg {
  padding: 10px 20px !important;
  background: #0bd24e !important;
  color: white !important;
  border-radius: 4px !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  font-size: 14px !important;
}

.main-calcu button:hover {
  background: #0bd24e;
  color: #fff !important;
}

.main-calcu button:active {
  background: #0bd24e;
  color: #fff !important;
}

/* .main-calcu button:nth-child(7n) {
  background: #0bd24e;
  color: white;
}

.main-calcu button:nth-child(7n):hover {
  background: #0bd24e;
}

.main-calcu button:nth-child(7n):active {
  background: #0bd24e;
} */

/* new Added */
.Scientific-container {
  width: 80%;
  border: 1px solid black;
  border-radius: 5px;
  margin: auto;
  margin-top: 90px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%);
  box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.5px);
}

.calculator-container {
  background-color: whitesmoke;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

.small-calculator-container {
  display: none;
}

.small-calculator-toggle {
  display: none;
}

/* Styles for small screens */
@media screen and (max-width: 600px) {
  .calculator-container {
    display: none;
  }

  .small-calculator-container {
    display: block;
    background-color: whitesmoke;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .small-calculator-toggle {
    display: block;
  }
}

.calculator-button {
  height: 60px;
  width: 100%;
  background: #f9f9f9;
  border: 1px solid #ddd;
  font-size: 1.2em;
  padding: 0;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.calculator-button:hover {
  background-color: green;
}

.calculator-button-bg {
  background-color: #0bd24e !important;
  color: #fff !important;
}

.calculator-button:active {
  background-color: green;
}

.calculator-input[type="text"] {
  width: 100%;
  height: 80px;
  font-size: 40px;
  text-align: right;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  box-sizing: border-box;
  background: #f1f1f1;
}

.visible-hidden {
  visibility: hidden !important;
}

.other-commn-page {
  /* padding-top: 200px; */
  margin-bottom: 50px;
}

.other-commn-page>div h1 {
  color: #28282b;
  text-align: center;
  font-size: 35px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 6.75px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 0;
  margin-bottom: 40px;
}

.other-commn-page h2 {
  color: #28282b;
  font-size: 25px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.75px;
}

.other-commn-page p {
  color: #28282b;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}

.other-commn-page ul li {
  margin-bottom: 10px;
}

.table-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  /* Optional: Add spacing from top */
}

.table-container table {
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  /* Limit max width of the table */
  text-align: center;
}

.table-container table,
th,
td {
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* Single border around table and cells */
  text-align: center;
}

.table-container th,
td {
  padding: 8px;
  text-align: center;
}

.table-container th {
  background-color: #ffffff00;
  /* Optional: Background color for header */
}

.contact-commn-box {
  border-radius: 10px;
  background: #f1f1f1;
  padding: 20px;
  margin-bottom: 20px;
}

.contact-us-view {
  display: flex;
  text-align: center;
  gap: 80px;
}

.contact-commn-box h2 {
  color: #0bda51;
  text-align: center;
  font-family: "Open Sans";
  font-size: 25px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0;
}

.contact-commn-box h4 {
  color: #28282b;
  font-family: "Open Sans";
  font-size: 25px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}

.line-middle {
  width: 3px;
  background: #ccc;
}

.contact-commn-box p {
  color: #787878;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-bottom: 10px;
}


.single-blog-left h3 {
  color: rgba(40, 40, 43, 0.50);
  font-family: "Mandatory Plaything";
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.7px;
}

.single-blog-left h2 {
  color: #28282B;
  font-family: "Mandatory Plaything";
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.5px;
}

.blog-admin {
  display: flex;
  gap: 10px;
  margin: 20px 0px;
}

.blog-admin>div h5 {
  color: #0F0F0F;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.blog-admin>div p {
  color: #A7A7A7;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.6px;
  margin-top: 5px;
  margin-bottom: 0;
}

.single-blog-left p {
  color: #6B6B6B;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-top: 0;
}

.blog-admin img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 10px;
}

.single-blog-left h4 {
  color: #0F0F0F;
  font-family: "Open Sans";
  font-size: 25px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.single-blog-more {
  border-radius: 20px;
  border: 1px solid #ECECEC;
  background: #FFF;
  box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  /* margin-bottom: 20px; */
  height: max-content;
}

.single-blog-data {
  display: grid;
  grid-template-columns: 65% 30%;
  justify-content: space-between;
}

.single-blog-left .blog-txt-data>img {
  width: 100%;
}


.single-blog-more ul {
  padding: 0;
  list-style: none;
  margin: 0;
}


.single-blog-more ul li img {
  width: 100%;
}


.blog-more-card h5 {
  color: rgba(40, 40, 43, 0.50);
  font-family: "Mandatory Plaything";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 5px;
}

.blog-more-card p {
  color: #0F0F0F;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-top: 0;
  margin-bottom: 0;
}

.blog-more-title p {
  margin-top: 0;
  color: #0F0F0F;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}


.single-blog-left h5 {
  color: #0F0F0F;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}


.blog-txt-data h4 {
  color: #0F0F0F;
  font-family: "Open Sans";
  font-size: 25px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}


.blog-txt-data h5 {
  color: #0F0F0F;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 10px;
}

.blog-txt-data p {
  color: #6B6B6B;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-top: 0;
}

.blog-more-card {
  text-decoration: none;
}

.single-blog-more ul hr {
  background-color: #ECECEC;
  border: none;
  height: 1.5px;
  /* Adjust height as needed */
}



/* // BMI CSS */

.gauge {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: #f0f0f0;
  position: relative;
}

.gauge-scale {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gauge-segment {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #333;
}

.gauge-segment.underweight {
  background-color: #87cefa;
}

.gauge-segment.normal {
  background-color: #90ee90;
}

.gauge-segment.overweight {
  background-color: #ffd700;
}

.gauge-segment.obese {
  background-color: #ff8c00;
}

.gauge-segment.extremely-obese {
  background-color: #ff4500;
}

.gauge-needle {
  position: absolute;
  top: 0;
  left: 50%;
  transform-origin: 50% 100%;
  width: 5px;
  height: 150px;
  background-color: black;
}

.needle-tip {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 50%;
}

.gauge-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
}